<template>
    <div v-if="active">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'step',
    data () {
        return {
            id: 0,
            hasBack: false,
            hasNext: false
        };
    },
    props: {
        'title': String,
        'icon_class': String,
        wizard: {
            type: Object
        },
        backLabel: {
            type: String,
            default: 'Back'
        },
        nextLabel: {
            type: String,
            default: 'Next'
        },
        onEnter: {
            type: Function
        },
        onBack: {
            type: Function
        },
        nextEnabled: {
            type: [Function, Boolean],
            default: undefined
        },
        backEnabled: {
            type: [Function, Boolean],
            default: undefined
        },
        onNext: {
            type: Function
        }
    },
    computed: {
        active () {
            return this.wizard.currentStepId === this.id;
        }
    },
    created () {
        this.id = this.wizard.addStep(this);
    },
    mounted () {
    },

    methods: {
        finalize () {
            this.hasBack = this.id > 1;
            this.hasNext = this.id < this.wizard.steps.length;
        },
        evaluate (property, defaultValue) {
            if (typeof property === 'function') {
                return property(this.wizard);
            } else if (typeof property === 'boolean') {
                return property;
            } else {
                return defaultValue;
            }
        },
        backDisabled () {
            return !this.evaluate(this.$props.backEnabled, this.hasBack);
        },
        nextDisabled () {
            return !this.evaluate(this.$props.nextEnabled, this.hasNext);
        }
    }
};
</script>

<style lang="scss">
.step-wrapper {
    padding: 20px 0;
    display: none;

    &.active {
        display: block;
    }
}
</style>
