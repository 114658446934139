<template>
    <table class="backgrid table table-hover">
        <thead>
        <tr>
            <th class="filter-header-cell sortable renderable" data-field="division"><a>Name</a>
            </th>
            <th class="filter-header-cell sortable renderable" data-field="division">
                <a>Username</a>
            </th>
            <th class="filter-header-cell sortable renderable" data-field="division"><a>Employee
                number</a></th>
            <th class="filter-header-cell sortable renderable" data-field="division"><a>{{reasonLabel}}</a></th>
            <th class="filter-header-cell sortable renderable" data-field="division" v-if="withStatus">
                <a>Status</a>
            </th>
        </tr>
        </thead>
        <tbody>
        <tr :key="employee._id" v-for="(employee) in selectedEmployees" :class="{error: employee.state === 'error'}">
            <td class="sortable renderable">{{employee.givenName}} {{employee.familyName}}</td>
            <td class="sortable renderable">{{employee.userName}}</td>
            <td class="sortable renderable">{{employee.employeeNumber}}</td>
            <td class="sortable renderable">{{employee.reason}}</td>
            <td class="sortable renderable" v-if="withStatus">
                <div class="icon"><i
                    :class="{'pending fa-regular fa-hourglass-half': employee.state === 'pending', 'success fa-regular fa-circle-check': employee.state === 'success', 'error fa-solid fa-bolt-lightning': employee.state === 'error'}"></i>
                </div>
                {{employee.status}}
            </td>
        </tr>
        </tbody>
    </table>
</template>

<script>
export default {
    name: 'selected-employees',
    data () {
        return {};
    },
    props: {
        'selectedEmployees': { type: Array, required: true },
        'reasonLabel': { type: String, required: true },
        withStatus: { type: Boolean, default: true }
    }
};
</script>
