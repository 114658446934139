<template>
    <li :class="indicatorclass">
        <div class="step">
            <i :class="step.icon_class"/>
        </div>
        <div class="caption hidden-xs hidden-sm">Step <span v-text="step.id"></span>: <span v-text="step.title"></span>
        </div>
    </li>
</template>

<script>

import { library } from '@fortawesome/fontawesome-svg-core';
import { faUserSecret } from '@fortawesome/free-solid-svg-icons';

library.add(faUserSecret);
export default {
    name: 'multi-step-wizard-step',
    props: ['step', 'wizard'],
    components: {
    },
    created () {
    },
    mounted () {
    },
    computed: {
        indicatorclass () {
            return {
                active: this.step.id === this.wizard.currentStepId,
                complete: this.wizard.currentStepId > this.step.id
            };
        }
    }
};
</script>

<style lang="scss"></style>
