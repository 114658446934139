<template>
    <b-modal :id="modalId" modal-class="fr-full-screen" ref="fsModal" cancel-variant="outline-secondary"
             @keydown.enter.native.prevent="next" @show="onShow">

        <template #modal-footer="{ ok, cancel, hide }">
            <template v-if="currentStep">
                <b-button variant="outline-secondary" @click="cancel()">
                    Cancel
                </b-button>
                <b-button variant="outline-secondary" @click="back()" :disabled="currentStep.backDisabled()">
                    {{currentStep.backLabel}}
                </b-button>
                <b-button variant="primary" @click="next()" :disabled="currentStep.nextDisabled()" ref="nextButton">
                    {{currentStep.nextLabel}}
                </b-button>
            </template>
        </template>

        <div slot="modal-header" class="d-flex w-100 h-100">
            <h5 class="modal-title align-self-center text-center">
                {{title}}</h5>
            <button type="button" aria-label="Close" class="close" @click="hideModal"><i class="fa fa-times"></i>
            </button>
        </div>
        <b-container>
            <div class="container">
                <form name="blockEmployeesForm" @submit.prevent="next">
                    <div>
                        <ol class="step-indicator">
                            <li v-for="step in steps" is="multi-step-wizard-step" :key="step.id" :step="step"
                                :wizard="wizard">
                            </li>
                        </ol>
                        <slot v-bind:wizard="wizard"></slot>
                    </div>
                </form>
            </div>
        </b-container>
    </b-modal>
</template>

<script>

import MultiStepWizardStep from '@/components/dashboard/widgets/userlifecycle/MultiStepWizardStep.vue';
import ResourceMixin from '@/components/utils/mixins/ResourceMixin.vue';

export default {
    name: 'multi-step-wizard',
    mixins: [
        ResourceMixin
    ],
    components: {
        'multi-step-wizard-step': MultiStepWizardStep
    },
    data () {
        return {
            steps: [],
            currentStepId: 0,
            currentStep: null
        };
    },
    props: ['id', 'title'],
    computed: {
        modalId () {
            return this.id + 'Modal';
        },
        wizard () {
            return this;
        }
    },
    methods: {
        addStep (step) {
            const id = this.steps.length + 1;
            this.steps.push(step);
            return id;
        },
        goTo (stepId) {
            this.currentStepId = stepId;
            this.currentStep = this.steps[this.currentStepId - 1];
            this.onEnter();
        },
        goBack () {
            if (this.currentStepId > 1) {
                this.goTo(this.currentStepId - 1);
            }
        },
        goNext () {
            if (this.currentStepId < this.steps.length) {
                this.goTo(this.currentStepId + 1);
            }
        },
        onEnter () {
            this.currentStep.hasBack = this.currentStepId > 1;
            this.hasNext = this.currentStepId < this.steps.length;
            this.focusNext();
            if (this.currentStep.onEnter) {
                this.currentStep.onEnter(this);
            }
        },
        back () {
            if (this.currentStep.hasBack) {
                if (this.currentStep.onBack) {
                    this.currentStep.onBack(this);
                } else {
                    this.goBack();
                }
            }
        },
        next () {
            if ((this.currentStep.nextEnabled && this.currentStep.nextEnabled()) || (!this.currentStep.nextEnabled && this.currentStep.hasNext)) {
                if (this.currentStep.onNext) {
                    this.currentStep.onNext(this);
                } else {
                    this.goNext();
                }
            }
        },
        onShow () {
            this.steps = [];
            setTimeout(() => {
                this.steps.forEach(step => {
                    step.finalize();
                });
                if (this.steps.length > 0) {
                    this.goTo(1);
                }
            }, 100);
        },
        hideModal () {
            this.$refs.fsModal.hide();
        },
        updateControls () {
            this.$root.$emit('multi-step-wizard.' + this.id + '.controls.update', this);
        },
        focusNext () {
            this.$refs.nextButton.focus();
        }
    }
};
</script>

<style lang="scss">
$wizard-color-neutral: #ccc !default;
$wizard-color-active: #2e5cff !default;
$wizard-color-complete: #2446be !default;
$wizard-step-width-height: 64px !default;
$wizard-step-font-size: 24px !default;

.step-indicator {
    border-collapse: separate;
    display: table;
    margin-left: 0px;
    position: relative;
    table-layout: fixed;
    text-align: center;
    vertical-align: middle;
    padding-left: 0;
    padding-top: 20px;

    li {
        display: table-cell;
        position: relative;
        float: none;
        padding: 0;
        width: 1%;

        &:after {
            background-color: $wizard-color-neutral;
            content: "";
            display: block;
            height: 1px;
            position: absolute;
            width: 100%;
            top: $wizard-step-width-height/2;
        }

        &:after {
            left: 50%;
        }

        &:last-child {
            &:after {
                display: none;
            }
        }

        &.active {
            .step {
                border-color: $wizard-color-active;
                color: white;
                background-color: $wizard-color-active;
            }

            .caption {
                color: black;
            }
        }

        &.complete {
            &:after {
                background-color: $wizard-color-complete;
            }

            .step {
                border-color: $wizard-color-complete;
                color: $wizard-color-complete;
            }

            .caption {
                color: #808080;
            }
        }
    }

    .step {
        background-color: #fff;
        border-radius: 50%;
        border: 1px solid $wizard-color-neutral;
        color: $wizard-color-neutral;
        font-size: $wizard-step-font-size;
        height: $wizard-step-width-height;
        line-height: $wizard-step-width-height;
        margin: 0 auto;
        position: relative;
        width: $wizard-step-width-height;
        z-index: 1;

        &:hover {
            cursor: pointer;
        }
    }

    .caption {
        color: $wizard-color-neutral;
        padding: 11px 16px;
    }
}
</style>
